.simple-chart {
  background: #fff;
  padding: 40px 0px;
}
.simple-charttwo {
  background: #fff;
  /* padding: 40px 0px; */
}
.Account-Actives-purshases {
  padding: 24px 0px 42px 0px;
}
.chat-bg {
  background: #fff;
  border-radius: 5px;
  padding: 15px 5px;
}

/* .main-table-container thead tr th:first-child,
.main-table-container tbody tr th:first-child {
  text-align: left !important;
} */
/*member*/
.add-budget-anchor a {
  color: "#A1A1A1";
  text-decoration: underline;
}
/* Workflow css  */
.btn-link-div {
  display: flex;
  gap: 29px;
  justify-content: center;
  align-items: center;
}
.btn-shop-for-workflow {
  border: 1px solid #d7f62f;
  background: #d7f62f;
  border-radius: 5px;
  color: #fff;
  padding: 4px 34px;
  border-radius: 15px;
  cursor: pointer;
}
.workflow-Approval {
  background: #29c01c;
  border: 1px solid #29c01c;
  padding: 4px 34px;
  border-radius: 15px;
  cursor: pointer;
}
.workflow-Approval:hover {
  background: #29c01c;
}
button.deny-btn {
  border: none;
  text-decoration: underline;
  font-weight: 500;
  background: none;
}
.btn-shop-for-workflow:hover {
  background: #d7f62f;
  color: #000;
}
.workflow-Approval-pending {
  background: #ff4f4b;
  border: 1px solid #ff4f4b;
  padding: 4px 34px;
  border-radius: 15px;
  cursor: pointer;
}
.workflow-Approval-pending:hover {
  background-color: #ff4f4b;
  color: #fff;
}

.viewersWorkflow-Approval-pending {
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  padding: 4px 34px;
  border-radius: 15px;
  cursor: pointer;
}
.viewersWorkflow-Approval-pending:hover {
  background-color: #d9d9d9;
  color: #fff;
}

.viewersWorkflow-Approval-preview {
  background: #000;
  color: #fff;
  border: 1px solid #000;
  padding: 4px 34px;
  border-radius: 15px;
  cursor: pointer;
}
.viewersWorkflow-Approval-preview:hover {
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
}

.viewersWorkflow-Approval-declined {
  background: #ff4f4b;
  border: 1px solid #ff4f4b;
  padding: 4px 34px;
  border-radius: 15px;
  cursor: pointer;
}
.viewersWorkflow-Approval-declined:hover {
  background-color: #ff4f4b;
  color: #fff;
}

.viewersWorkflow-Approval-approved {
  background: #29c01c;
  border: 1px solid #29c01c;
  padding: 4px 34px;
  border-radius: 15px;
  cursor: pointer;
}
.viewersWorkflow-Approval-approved:hover {
  background-color: #29c01c;
  color: #fff;
}

.workflow-Approval-preview {
  background: #000;
  color: #fff;
  border: 1px solid #000;
  padding: 4px 34px;
  border-radius: 15px;
  cursor: pointer;
}
.workflow-Approval-preview:hover {
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
}

.workflow-Approval-checkout {
  background: #d7f62f;
  color: #000;
  border: 1px solid #d7f62f;
  padding: 4px 34px;
  border-radius: 15px;
  cursor: pointer;
}
.workflow-Approval-checkout:hover {
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
}

/* Previous Orders Table CSS */
.previousorderrequestsheading {
  color: "#0C151B";
  font-weight: 500;
  padding-top: 41px;
  padding-bottom: 16px;
}
.individualspendingheading {
  padding-bottom: 73px;
}
