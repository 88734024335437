section.Account-Actives {
  padding: 24px 0px;
}
.grid-container {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(240px, 1fr)); */
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-top: 20px;
}
.grid-container-dashboard {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(240px, 1fr)); */
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-top: 20px;
}
.grid-item {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 16px;
  text-align: start;
}
.grid-boxies {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding-bottom: 31px;
}

.activity-box {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.account-active {
  background: #fff;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
}
.btn-div {
  display: flex;
  justify-content: end;
  align-items: center;
}
button.btn.btn-shop {
  border: 1px solid #d7f62f;
  background: #d7f62f;
  padding: 15px 30px;
  border-radius: 1px;
}
.main-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 60px;
}
button.btn-edit {
  background: none;
  border: none;
}
button.btn-edit a {
  color: gray;
}
button.btn-deny {
  background: none;
  border: none;
}
button.btn-deny a {
  color: #000;
  text-align: center;
  /* font-family: Helvetica Neue; */
  font-size: 12px;
  font-weight: 500;
  text-decoration-line: underline;
}
section .progress-graph {
  padding: 30px 0px;
}
.chart-bg {
  background: #fff;
  padding: 0px 0 15px 0px;
}
.empty-box {
  width: 70px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95px;
}

/* Members pure css */
.create-div-btn.MuiBox-root.css-0 {
  text-align: end;
  padding-top: 34px;
}
button.create-data-btn {
  cursor: pointer;
  border-radius: 3px;
  background: #d7f62f;
  border: none;
  padding: 13px 19px;
}
.export-link-div {
  padding-top: 20px;
}
.export-link-div a {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  text-decoration-line: underline;
}

/* pure purchase css */

/* Data Table Done */
.table-heading {
  color: #000;
  display: flex;
  justify-content: space-between;
  padding: 17px;
}
.main-table-contaier .css-1oyq3vl-MuiTableCell-root {
  color: #000;
}
.main-table-contaier .css-n0xq8q-MuiTableCell-root {
  border-bottom: none;
}
.main-table-contaier .css-1oyq3vl-MuiTableCell-root {
  border-bottom: none;
}

/* WorkFlow */

.preview-chip:hover { 
  color: #000;
}