.datepicker-div .css-1qxadfk-MuiPaper-root-MuiDialog-paper {
  background-color: #fff;
  color: #000;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.btn-confirm-datepicker.css-301o66-MuiButtonBase-root-MuiButton-root {
  background: #d7f62f;
  padding: 10px 30px;
}
.css-ypiqx9-MuiDialogContent-root {
  overflow-y: none;
}

/* TopBar CSS */
.custom-topbar {
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 7%;
}
