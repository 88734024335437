@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
  }
}

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1399px) {
}

@media screen and (max-width: 1199px) {
}

@media screen and (max-width: 1024px) {
  .css-14fernp {
    position: absolute;
    /* top: 0; */
    /* bottom: 0; */
    /* height: 100%; */
  }

  .grid-container-dashboard {
    grid-template-columns: 1fr 1fr;
  }

  .app {
    /* display: grid;
    grid-template-columns: 1fr; */
    /* display: block; */
  }

  .css-14fernp {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .grid-container-dashboard {
    grid-template-columns: 1fr 1fr;
  }

  .grid-boxies {
    grid-template-columns: 1fr;
  }

  .app {
    display: flex;
    padding-left: 0px;
  }

  .grid-container {
    grid-template-columns: 1fr;
  }

  .individualspendingheading {
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 991px) {
  .css-14fernp {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .grid-container-dashboard {
    grid-template-columns: 1fr 1fr;
  }

  .grid-boxies {
    grid-template-columns: 1fr;
  }

  .app {
    display: flex;
    padding-left: 0px;
  }

  .grid-container {
    grid-template-columns: 1fr;
  }

  .individualspendingheading {
    padding-bottom: 16px;
  }
  .css-1wawrla {
    position: absolute;
    height: 100%;
  }
  main.content {
    padding-left: 69px;
  }
  

  /* .css-1wawrla {
    position: absolute;
    height: 100%;
  } */
}

@media screen and (max-width: 767px) {
  .css-14fernp {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .grid-container-dashboard {
    grid-template-columns: 1fr 1fr;
  }

  .grid-boxies {
    grid-template-columns: 1fr;
  }

  .custom-topbar {
    display: block;
  }

  .app {
    display: flex;
    /* padding-left: 80px; */
    padding-left: 0px;
  }

  .grid-container {
    grid-template-columns: 1fr;
  }
  .individualspendingheading {
    padding-bottom: 16px;
  }
  .custom-topbar {
    padding-right: 10%;
  }
  .Account-Actives-purshases {
    display: grid;
  }
  .time-and-date {
    text-align: left;
  }
  .css-ju4zfe {
    display: grid;
  }
  .css-1wawrla {
    position: absolute;
    height: 100%;
  }

  main.content {
    padding-left: 69px;
  }
  /* .css-1wawrla {
    position: absolute;
    height: 100%;
  } */
}

@media screen and (max-device-height: 414px) and (orientation: landscape) {
}

@media screen and (max-width: 575px) {
  .custom-topbar {
    display: block;
  }
  .grid-container-dashboard {
    grid-template-columns: 1fr 1fr;
  }
  .grid-container {
    grid-template-columns: 1fr;
  }
  .btn-div {
    /* justify-content: flex-start; */
    text-align: end;
  }
}

@media screen and (max-width: 479px) {
  .custom-topbar {
    display: block;
  }
  .grid-container-dashboard {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 320px) {
  .main-box {
    display: block;
  }
}
