.my-table .css-paus8o-MuiTableCell-root {
  background-color: #fff;
  color: grey;
  border: none;
  padding-bottom: 0px;
}
.my-table .css-1oyq3vl-MuiTableCell-root {
  background-color: #fff;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  padding: 15px 20px 15px 16px;
}
.my-table .css-1q3trro-MuiPaper-root {
  box-shadow: none;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiTableContainer-root.css-lbhu6r-MuiPaper-root-MuiTableContainer-root {
  padding: 20px;
}