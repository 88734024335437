.logo {
  width: 100%;
  height: auto;
  flex-shrink: 0;
}
.menu-routes-parent .css-1l8icbj {
  padding: 0%;
}
.slider-active-menu {
  border-radius: 5px;
  background: #232a2e;
  padding: 10px;
  margin: 0 8px;
  color: #fff;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover svg,
.pro-sidebar .pro-menu .pro-menu-item.active svg {
  fill: #ffff;
}
.pro-sidebar .pro-menu .pro-menu-item svg {
  fill: #858a8d;
  width: 20px;
  height: auto;
}
span.MuiBadge-badge.MuiBadge-standard.MuiBadge-anchorOriginTopRight.MuiBadge-anchorOriginTopRightCircular.MuiBadge-overlapCircular.MuiBadge-colorError.css-8r0lit-MuiBadge-badge {
  background: #d7f62f;
  right: -10px;
}
.pro-sidebar .pro-menu .pro-menu-item.active {
  margin: 0;
}
.menu-routes-parent .css-1l8icbj {
  margin: 0 8px;
}

.blur {
  filter: blur(2px);
  pointer-events: none;
}